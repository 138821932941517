














































































































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { DateFilterDialogProperties } from '@/components/shared/DateFilterDialogView.vue';
import ListViewMixin from "@/components/shared/ListViewMixin";
import SessionClosingService from "@/services/SessionClosingService";
import SpreadsheetService from "@/services/SpreadsheetService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import RouteUtil from "@/utilities/RouteUtil";
import StringUtil from "@/utilities/StringUtil";
import DateUtil from "@/utilities/DateUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import FilterUtil from '@/utilities/FilterUtil';

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const DateFilterDialogView = () => import("@/components/shared/DateFilterDialogView.vue");

@Component({
    components: { AlertDialogView, DateFilterDialogView },
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            loading: false,
        },
        filter: {
            uniqueNo: "",
            closingDateFrom: null,
            closingDateTo: null,
			finalizedDateNull: false,
            finalizedDateFrom: null,
            finalizedDateTo: null,
            status: "All"
        },
        sessionClosings: [],
    }),
})
export default class SessionClosingListView extends Mixins(ListViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private dateFilterDialog = new DateFilterDialogProperties();
    private sessionClosingService = new SessionClosingService();
	private spreadsheetService = new SpreadsheetService();
    private dateTimeFormat: string = null;
    private dateFormat: string = null;

    public get headers() {
        return [
            {
                text: this.$t("text.closing-no"),
                value: "uniqueNo",
                width: "15%",
            },
            {
                text: this.$t("text.closing-date"),
                value: "closingDate",
                width: "180px"
            },
            {
                text: this.$t("text.finalized-date"),
                value: "finalizedDate",
                width: "180px"
            },
            {
                text: this.$t("text.status"),
                value: "status",
                width: "180px"
            },
            {
                text: "",
                value: "dummy"
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "164px",
                sortable: false,
            },
        ];
    }

    public statusOptions(withAll: boolean = false) {
        return [
            {
                text: this.$t('text.all'),
                value: "All",
                all: true
            },
            {
                text: this.$t('text.open'),
                value: "Open",
                all: false
            },
            {
                text: this.$t('text.finalized'),
                value: "Finalized",
                all: false
            }
        ].filter(x => !x.all || withAll);
    }

    public canDelete(item: any) {
        return item.status !== "Finalized";
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public formatStatus(v: any) {
        return this.statusOptions().find(x => x.value === v).text;
    }

    public created() {
        this.listenVisibleChanged();

        const data = this.$data;
        const query = this.$route.query;

        data.table.pageNo = parseInt(query.pageNo as any ?? 1);
        data.table.recordCount = parseInt(query.recordCount as any ?? 10);

        data.filter.uniqueNo = query.sessionNo ?? "";
        data.filter.closingDateFrom = FilterUtil.dateOf(query.closingDateFrom);
        data.filter.closingDateTo = FilterUtil.dateOf(query.closingDateTo);
		data.filter.finalizedDateNull = FilterUtil.booleanOf(query.finalizedDateNull);
		data.filter.finalizedDateFrom = FilterUtil.dateOf(query.finalizedDateFrom);
		data.filter.finalizedDateTo = FilterUtil.dateOf(query.finalizedDateTo);
        data.filter.status = query.status ?? "All";

        this.load();
    }

    public destroyed() {
        this.removeVisibleChanged();
    }

    public visibleChanged(e) {
        if (!document.hasFocus()) this.load();
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;
            const status = filter.status === 'All' ? null : filter.status;

            const r = await this.sessionClosingService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                uniqueNo: StringUtil.enclose(filter.uniqueNo, "%", "%"),
                closingDate: FilterUtil.filterDate(
					false, 
					filter.closingDateFrom, 
					filter.closingDateTo
				),
				finalizedDate: FilterUtil.filterDate(
					filter.finalizedDateNull, 
					filter.finalizedDateFrom, 
					filter.finalizedDateTo
				),
                status
            });
            this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.dateFormat = ResponseUtil.getDateFormat(r);

            const sessionClosings = r.data.sessionClosings;
            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            this.$data.sessionClosings = sessionClosings.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }

            const url = RouteUtil.makeUrl("/management/session-closings", {
                pageNo: this.$data.table.pageNo,
                recordCount: rowCount,
                sessionNo: filter.uniqueNo,
                closingDateFrom: filter.closingDateFrom,
                closingDateTo: filter.closingDateTo,
				finalizedDateNull: filter.finalizedDateNull,
				finalizedDateFrom: filter.finalizedDateFrom,
				finalizedDateTo: filter.finalizedDateTo,
                status
            });
            if (this.$route.fullPath !== url) {
                await this.$router.replace(url);
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public removeDialog(record: any) {
        AlertDialogProperties.delete(this.alertDialog, record.code);
        this.alertDialog.tag = { command: "remove", record };
        this.alertDialog.visible = true;
    }

    public async remove(record: any) {
        try {
            await this.sessionClosingService.delete(record);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public filterClosingDate() {
        const filter = this.$data.filter;
		this.dateFilterDialog.nullable = false;
		this.dateFilterDialog.dateNull = false;
        this.dateFilterDialog.dateFrom = filter.closingDateFrom;
        this.dateFilterDialog.dateTo = filter.closingDateTo;
        this.dateFilterDialog.dateFormat = this.dateFormat;
        this.dateFilterDialog.title = this.$t('text.closing-date').toString();
        this.dateFilterDialog.labelDateFrom = this.$t('text.date-from').toString();
        this.dateFilterDialog.labelDateTo = this.$t('text.date-to').toString();
        this.dateFilterDialog.tag = 'closing-date';
        this.dateFilterDialog.visible = true;
    }

    public filterFinalizedDate() {
        const filter = this.$data.filter;
		this.dateFilterDialog.nullable = true;
		this.dateFilterDialog.dateNull = filter.finalizedDateNull;
        this.dateFilterDialog.dateFrom = filter.finalizedDateFrom;
        this.dateFilterDialog.dateTo = filter.finalizedDateTo;
        this.dateFilterDialog.dateFormat = this.dateFormat;
        this.dateFilterDialog.title = this.$t('text.finalized-date').toString();
        this.dateFilterDialog.labelDateFrom = this.$t('text.date-from').toString();
        this.dateFilterDialog.labelDateTo = this.$t('text.date-to').toString();
        this.dateFilterDialog.tag = 'finalized-date';
        this.dateFilterDialog.visible = true;
    }

    public async filteredDate() {
        const filter = this.$data.filter;
        if (this.dateFilterDialog.tag === 'closing-date') {
            filter.closingDateFrom = this.dateFilterDialog.dateFrom;
            filter.closingDateTo = this.dateFilterDialog.dateTo;
            await this.load();
        } else if (this.dateFilterDialog.tag === 'finalized-date') {
			filter.finalizedDateNull = this.dateFilterDialog.dateNull;
			filter.finalizedDateFrom = this.dateFilterDialog.dateFrom;
			filter.finalizedDateTo = this.dateFilterDialog.dateTo;
			await this.load();
		}
    }

	public exportData() {
		const filter = this.$data.filter;
		const status = filter.status === 'All' ? null : filter.status;
        
        const url = this.spreadsheetService.sessionClosingUrl("xlsx", {
			closingNo: StringUtil.enclose(filter.uniqueNo, "%", "%"),
			closingDate: FilterUtil.filterDate(
				false, 
				filter.closingDateFrom, 
				filter.closingDateTo
			),
			finalizedDate: FilterUtil.filterDate(
				filter.finalizedDateNull, 
				filter.finalizedDateFrom, 
				filter.finalizedDateTo
			),
			status
        });

        window.open(url, "_blank");
	}

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag && tag.command === "remove") {
            if (button === this.$t("text.yes")) {
                await this.remove(tag.record);
            }
        }
    }
}
